<template>
  <div>

    <div class="notificacao" style="position: absolute;" ng-mouseenter="notify.boolPausar=1"
      ng-mouseleave="notify.boolPausar=0">
      <div v-for="(notifica, index) in $store.state.util.listaMensagens" :key="index" :class="'notification rs-notify ng-scope is-' + notifica.type"
        ng-class="notifica.classe" :style="notifyPosition(notifica, index)">
        <!-- ngIf: notifica.strLink -->
        <button class="delete is-medium" @click="notifyRemover(index)"></button>
        <strong class="ng-binding" style="font-size:10pt">{{ notifica.titulo }}</strong>
        <p v-html="notifica.mensagem" style="font-size:10pt" class="ng-binding"></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "notificationService",
  components: {},
  mixins: [],
  data() {
    return {
      listaMensagens: [],
      type: 'success'
    };
  },

  created() { },
  beforeDestroy() {
  },

  mounted() {

  },

  watch: {
    listaMensagens: {
      handler: function (newVal) { },
      deep: true
    },
  },

  methods: {

    notifyRemover(index) {
      this.$store.state.util.listaMensagens.splice(index, 1)
    },

    notifyPosition(object, index) {
      let margemInferior = 35
      return (
        "z-index:" + (9999 - index) + ";right:15px;top:15px;bottom:auto;left:auto;asfs:as"
      )
    },

    danger(message) {
      this.type = 'danger'
      this.$store.state.util.listaMensagens.push({
        mensagem: message,
        titulo: 'Erro',
        type:'danger'
      })
    },

    success(message) {
      this.type = 'success'
      this.$store.state.util.listaMensagens.push({
        mensagem: message,
        titulo: 'Sucesso',
        type:'success'
      })

      let self = this
      let index = this.$store.state.util.listaMensagens.length - 1

      setTimeout(() => {
        self.notifyRemover(index)
      }, 3000);
    },
    warning(message) {
      this.type = 'warning'
      this.$store.state.util.listaMensagens.push({
        mensagem: message,
        titulo: 'Atenção',
        type:'warning'
      })

      let self = this
      let index = this.$store.state.util.listaMensagens.length - 1

      setTimeout(() => {
        self.notifyRemover(index)
      }, 8000);
    }
  },
};
</script>