import { createStore } from 'vuex'
import produto from './produto'
import atributo from './atributo'
import menu from './menu'
import usuario from './usuario'
import cliente from './cliente'
import util from './util'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    produto,
    atributo,
    menu,
    usuario,
    cliente,
    util
  }
})
