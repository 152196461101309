const setData = (context, data) => {
	context.commit('setData', data);
}

const atualizar = (context, data) => {
	context.commit('atualizar', data);
}

export {
	setData,
	atualizar
}