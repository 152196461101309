const SET_CLIENTE_LOGADO = (state,value) => {
	state.logado = value
}

const SET_VERSAO= (state,value) => {
	state.versao = value
}


export{
	SET_CLIENTE_LOGADO,
	SET_VERSAO
}
