const setClienteLogado = (context, data) => {
	context.commit('setClienteLogado', data);
}

const setVersao = (context, data) => {
	context.commit('SER_VERSAO', data);
}
export {
	setClienteLogado,
	setVersao
}