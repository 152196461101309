export default {
	boolSidebarAtivo:false,
	menus:[],
	menuResumido:[],
	menuCompleto:[],
	abas: {
		lista: [],
		currentIndex: 0
	},
	abasRecentes: [],
}