import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [{
    path: '/',
    name: '',
    component: function () {
      return import('@/layout/LoginLayout.vue')
    },
    children: [{
      path: '',
      name: 'login',
      component: () => import('@/views/LoginView'),
      meta: {
        boolRequiredAuthentication: false,
        boolFooterDisble: true,
        boolTopDisble: true,
      },
    }, ]
  },
  {
    path: '/:strUrlDatabase/:strVersao',
    name: '',
    component: function () {
      return import('@/layout/AuthLayout.vue')
    },
    children: [{
      path: '',
      name: 'home',
      component: () => import('@/views/HomeView'),
      meta: {
        boolRequiredAuthentication: false,
        boolFooterDisble: true,
        boolTopDisble: true,
      },
    }, ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router