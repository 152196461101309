const SET_DATA = (state,value) => {
	state.logado = value
}


const atualizar = (state,value) => {
	state.atualizar = value
}


export{
	SET_DATA,
	atualizar
}
