<template>
  <div></div>
</template>

<script type="text/javascript">
import Axios from "axios";

export default {
  name: "requestService",
  components: {},
  mixins: [],
  data() {
    return {
      form: {
        url: null,
        method: null,
        service: null,
        params: null,
        filters: null,
        headers: null,
      },
      bind: {
        boolHeaderStratus: false
      }
    };
  },

  mounted() { },

  computed: {
  },
  methods: {
    get(_service, _params = {}) {
      this.form.service = _service + "/GET";
      this.form.params = _params;
      this.form.filters = _params;
      this.form.method = "GET";

      return this.request();
    },

    post(_service, _params = {}, _filters = {}, _disabledService = false) {
      _params = Object.assign(_params, _filters);

      this.form.service = _service + (_disabledService ? "" : "/POST");
      this.form.params = _params;
      this.form.filters = _filters;
      this.form.method = "POST";

      return this.request();
    },

    put(_service, _params = {}, _filters = {}) {
      _params = Object.assign(_params, _filters);

      this.form.service = _service + "/PUT";
      this.form.params = _params;
      this.form.filters = _filters;
      this.form.method = "PUT";

      return this.request();
    },

    delete(_service, _params = {}, _filters = {}) {
      _params = Object.assign(_params, _filters);

      this.form.service = _service + "/DELETE";
      this.form.params = _params;
      this.form.filters = _filters;
      this.form.method = "DELETE";

      return this.request();
    },

    open(_service, _params = {}) {
      let strUrl = this.getUrl() + "/";

      _params["token"] = this.$root.$session.getJwt();

      if (_params) {
        _params = !_params ? '' : $.param(_params);
      }

      strUrl += _service + "?" + _params;

      window.open(strUrl, "_blank");
    },

    url(_service, _params = {}) {
      let strUrl = this.getUrl() + "/";

      _params["token"] = this.$root.$session.getJwt();

      if (_params) {
        _params = new URLSearchParams(_params).toString();
      }

      strUrl += _service + "?" + _params;

      return strUrl;
    },

    getHeaders(disabledHeaders = false) {
      var headers = {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8"
      };

      if (this.$route.params.strUrlDatabase) {
        headers['Auth'] = this.$cookie.get("rid") ? this.$cookie.get("rid") : process.env.VUE_APP_DEVTOKEN
      }

      if (!this.bind.boolHeaderStratus && !this.$route.params.strUrlDatabase) {
        return {}
      }
      return headers;
    },

    setHeadersStatus(boolHeaderStratus) {
      this.bind.boolHeaderStratus = boolHeaderStratus
      return this
    },

    setHeaders: function () {
      this.form.headers = this.getHeaders();

      Axios.defaults.headers.common = this.form.headers;
    },

    setUrl(url = "") {
      this.form.url = (url ? url : this.getUrl())
      return this
    },

    getUrl(layout = "", boolBaseUrl = false) {

      if (this.form.url) {
        return this.form.url;
      }

      let url = null;

      if (this.$route.params.strUrlDatabase) {
        url = process.env.VUE_APP_CORE_URL + '/' + this.$route.params.strUrlDatabase + '/' + this.$route.params.strVersao + '/rest/'

      }
      else {
        url = process.env.VUE_APP_CORE_URL + '/' + this.$root.$session.cliente + '/' + this.$root.$session.versao + '/'
      }

      return url;
    },

    request() {
      this.setUrl();
      this.setHeaders();

      var response = {};

      return new Promise((resolve, reject) => {
        response = {};

        let promisses = [];
        Axios({
          method: this.form.method == "GET" ? "POST" : this.form.method,
          url: this.form.service,
          data: this.form.params,
          baseURL: this.form.url,
          timeout: 260000,
        })
          .then((result) => {
            this.objResponse = null;

            response = result.data;
            promisses.push(response);

            return Promise.all(promisses).then(
              (success) => {
                resolve(success[0]);
              },
              (err) => { }
            );
          })
          .catch((err) => {
            var error = Object.assign({}, err);

            if (error.response && error.response.data) {
              response = error.response.data
            }

            if (response.code == 403) {
              this.$root.$session.logout()
              return;
            }

            promisses.push(response);

            return Promise.all(promisses).then(
              (success) => {
                resolve(success[0]);
              },
              (err) => { }
            );
          })
          .finally((err) => {
            this.form.url = null;
          });
      });
    },
  },
};
</script>
