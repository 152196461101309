const setMenu = (context, data) => {
	context.commit('setMenu', data);
}

const setMenuResumido = (context, data) => {
	context.commit('setMenuResumido', data);
}

const setMenuCompleto = (context, data) => {
	context.commit('setMenuCompleto', data);
}

const setAba = (context, data) => {
	context.commit('setAba', data);
}

const setAbaAtiva = (context, data) => {
	context.commit('setAbaAtiva', data);
}

const setSidebar = (context, data) => {
	context.commit('setSidebar', data);
}

export {
	setMenu,
	setMenuResumido,
	setMenuCompleto,
	setAba,
	setAbaAtiva,
	setSidebar
}